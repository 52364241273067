import $ from 'jquery';

$(document).ready(function () {
	const $curator =$('.curator');
	if ($curator.length === 0) return;
	$(function() {
	var i, e, d = document, s = "script";i = d.createElement("script");i.async = 1;
	i.src = "https://cdn.curator.io/published/83d8842f-1a6b-47a0-8e2d-53d57467c827.js";
	e = d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
	});
});
