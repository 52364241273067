import AOS from 'aos';

$(document).ready(function () {
	AOS.init({
		delay: 100,
		duration: 500,
		ease: 'ease-in-out',
	});
	window.addEventListener('load', AOS.refresh);
});
