import $ from 'jquery';

$(document).ready(function () {
	$(document).click(function (ev) {
		$('.header-nav__item, .main-nav__item').removeClass('-is-active');
	});
	// Display submenu
	$('.has-submenu').click(function (e) {
		const $li = $(this).closest('li');
		$('.header-nav__item, .main-nav__item')
			.not($li)
			.removeClass('-is-active');

		$li.toggleClass('-is-active');
		e.preventDefault();
		e.stopPropagation();
	});

	// Notification
	$('.notification__dismiss').on('click', function () {
		$('.notification').addClass('-isHide');
	});

	//show search form
	$('.JS-searchToggle').on('click', function () {
		$(this).toggleClass('-isActive');
		$('.searchBar').toggleClass('-isActive');
		$('.searchBar input').focus();
		$('.mainNav__link').removeClass('-isSelected');
	});

	// Fixed header and add animation effect when scrolle down
	const checkHeaderFixed = () => {
		if (window.matchMedia('(min-width: 768px)').matches) {
			const scrollTop = $(window).scrollTop();
			if (scrollTop > 0) {
				$('body').addClass('bodyScrolled');
			} else {
				$('body').removeClass('bodyScrolled');
			}
		} else {
			$('body').removeClass('bodyScrolled');
		}
	};

	$(window).scroll(checkHeaderFixed);
	$(window).resize(checkHeaderFixed);
	checkHeaderFixed();
});
