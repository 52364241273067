
import $ from 'jquery';
$(document).ready(function () {
	$('.individual').click(function(e) {
		openTab(this, 'individualForm');
	})

	$('.organisation').click(function(e) {
		openTab(this, 'organisationForm');
	})

	function openTab(btn, tabName) {
		$('.getInTouch__tabButton').removeClass('-isActive');
		$(btn).addClass('-isActive');
		$('.getInTouch__form').removeClass('-isActive');
		$('.' + tabName).addClass('-isActive');
	}
});
