const bodyElement = document.querySelector('body');
const bodyAttribute = 'data-icons';
const iconsPath = bodyElement.getAttribute(bodyAttribute);

const inlineFile = iconsPath => {
	if (!iconsPath) {
		return console.warn(
			`No body attribute of "${bodyAttribute}" found for SVG icon sprite`
		);
	}
	const request = new XMLHttpRequest();
	request.open('GET', iconsPath, true);
	request.onload = () => {
		if (request.status >= 200 && request.status < 400) {
			const svgIcon = request.responseXML.documentElement;
			svgIcon.setAttribute('display', 'none');
			svgIcon.setAttribute('aria-hidden', true);
			bodyElement.insertBefore(svgIcon, bodyElement.firstChild),
				bodyElement.removeAttribute(bodyAttribute);
		}
	};
	request.send();
};
inlineFile(iconsPath);
