import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';

$(function() {
	var grids = document.querySelectorAll('.masonry-grid');
	if (grids) {
		grids.forEach(grid => {
			var msnry = new Masonry(grid, {
				// options...
				itemSelector: '.card',
				columnWidth: '.card',
				percentPosition: true,
				gutter: '.gutter-sizer',
			});
			$(grid).data('masonry', msnry);
			imagesLoaded(grid, () => msnry.layout());
		});
	}
});
