document.addEventListener('DOMContentLoaded', () => {
	const menuTitle = document.querySelector('.mobile-app-bar__button-text');
	const hamburger = document.querySelector('.mobile-app-bar__hamburger');
	const mobileMenu = document.querySelector('.mobile-menu');
	const toggleMobileMenu = document.querySelector('.toggle-mobile-menu');

	const mobileMenuExpands = mobileMenu.querySelectorAll(".mobile-menu__expand");

	toggleMobileMenu.addEventListener('click', (evnt) => {
		mobileMenu.classList.toggle('-isActive');
		hamburger.classList.toggle('-isActive');
		menuTitle.innerHTML = mobileMenu.classList.contains('-isActive')
			? 'Close'
			: 'Menu';
	});

	var closest = function closest(el, fn) {
		return el && (fn(el) ? el : closest(el.parentNode, fn));
	};

	mobileMenuExpands.forEach((mobileMenuExpand) => {
		mobileMenuExpand.addEventListener('click', (evnt) => {
			evnt.stopPropagation();
			evnt.preventDefault();
			const menuItem = closest(evnt.target, (el) => el.classList.contains('mobile-menu__item'));
			menuItem.classList.toggle('-isExpanded');
			document.querySelectorAll('.-isExpanded').forEach((x) => {if (menuItem != x) x.classList.remove('-isExpanded')});
		});
	});

});
