import $ from 'jquery';

// Set event listener before.
freeformReady($('.subscribeForm'));
freeformReady($('.organisationContactForm'), { usePopupSuccess: true });
freeformReady($('.individualContactForm'), { usePopupSuccess: true });
freeformReady($('form.form:not(.subscribeForm):not(.organisationContactForm):not(.individualContactForm)'));

$(function ($) {
	initSubscribeForm();
	initIndividualContactForm();
	initOrganisationContactForm();

	$('.popupSuccessMessage__close').click(function() {
		$(this).closest('.popupSuccessMessage').removeClass('-isActive');
	});
});

//#region init forms
function initSubscribeForm() {
	$('.subscribeForm').each((i, form) => {
		const $form = $(form);

		replaceSubmitButton($form);

		$form.validate({
			rules: {
				firstName: 'required',
				surname: 'required',
				email: {
					required: true,
					email: true,
				}
			},
			messages: {
				firstName: 'Please enter your First Name',
				surname: 'Please enter your Surname',
				email: {
					required: 'Please enter your Email address',
					email: 'Please enter a valid Email address',
				}
			}
		});
	});
}

const sharedRulesForContactForm = {
	rules: {
		firstName: 'required',
		lastName: 'required',
		email: {
			required: true,
			email: true,
		}
	},
	messages: {
		firstName: 'Please enter your First Name',
		lastName: 'Please enter your Last Name',
		email: {
			required: 'Please enter your Email address',
			email: 'Please enter a valid Email address',
		}
	}
}

function initIndividualContactForm() {
	$('.individualContactForm').each((i, form) => {
		const $form = $(form);
		replaceSubmitButton($form);
		$form.validate(sharedRulesForContactForm);
	});
}

function initOrganisationContactForm() {
	$('.organisationContactForm').each((i, form) => {
		const $form = $(form);
		replaceSubmitButton($form);
		$form.validate(sharedRulesForContactForm);
	});
}


//#endregion

function replaceSubmitButton($form) {
	const $submitBtn = $form.find('button[type="submit"]');
	const $clonedBtn = cloneButton($submitBtn);
	$clonedBtn.on('click', (e) => {
		$form.find('.formSuccessMessage').empty();
		$clonedBtn.prop('disabled', true);
		if ($form.valid()) {
			$submitBtn.click();
		} else {
			$clonedBtn.prop('disabled', false);
		}
	});
}

function cloneButton($btn) {
	const $btnCloned = $btn.clone();
	$btnCloned.prop('type', 'button');
	$btn.hide();
	$btnCloned.insertAfter($btn);
	return $btnCloned;
}
//#endregion

function freeformReady($form, opt) {
	
	const options = Object.assign('', {
		successMessage: 'Form submitted successfully',
		usePopupSuccess: false
	}, opt);

	$form.on('freeform-ready', function (event) {
		const freeform = event.target.freeform;

		freeform.setOption('renderFormErrors', function (errors) {
			$form.find('button').prop('disabled', false);
			const $msgPlaceHolder = $form.find('.formErrorMessages');
			$msgPlaceHolder.empty();

			if (errors.length) {
				const $errorsList = $('<ul></ul>').addClass('errors');
				for (let messageIndex = 0; messageIndex < errors.length; messageIndex++) {
					const $li = $('<li></li>').html(errors[messageIndex]);
					$li.appendTo($errorsList);
				}
				$errorsList.appendTo($msgPlaceHolder);
			}
		});

		// Success message
		if (options.usePopupSuccess) {
			freeform.setOption('renderSuccess', function () {
				$form.find('button').prop('disabled', false);
				const $msgPlaceHolder = $form.parent().find('.popupSuccessMessage').addClass('-isActive');
				const $successMessage = $msgPlaceHolder.find('.popupSuccessMessage__message');
				$successMessage.html(options.successMessage);
				window.setTimeout(()=> {$msgPlaceHolder.removeClass('-isActive')}, 2000);
			});
		} else {
			freeform.setOption('renderSuccess', function () {
				$form.find('button').prop('disabled', false);
				const $msgPlaceHolder = $form.find('.formSuccessMessage');
				$msgPlaceHolder.empty();
				const $successMessage = $('<div></div>').addClass('success').html(options.successMessage);
				$successMessage.appendTo($msgPlaceHolder);
			});
		}
	});
}
