$(document).ready(function() {
	$('.gallery').slick({
		dots: false,
		autoplay: true,
		autoplaySpeed: 2000,
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: true,
		easing: 'easeOutElastic',
		nextArrow:
		'<button class="slick-next slick-arrow gallery__slickArrow" aria-label="Next" type="button"><i class="far fa-angle-right gallery__slickIcon" data-stick-cursor></i></div>',
		prevArrow:
		'<button class="slick-prev slick-arrow gallery__slickArrow" aria-label="Prev" type="button"><i class="far fa-angle-left gallery__slickIcon" data-stick-cursor></i></div>',
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	});

	const articleGallerySlick = () => {
		$('.article__gallery').slick({
			dots: false,
			autoplay: true,
			autoplaySpeed: 2000,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			easing: 'easeOutElastic',
			nextArrow:
			'<button class="slick-next slick-arrow gallery__slickArrow" aria-label="Next" type="button"><i class="far fa-angle-right gallery__slickIcon" data-stick-cursor></i></div>',
			prevArrow:
			'<button class="slick-prev slick-arrow gallery__slickArrow" aria-label="Prev" type="button"><i class="far fa-angle-left gallery__slickIcon" data-stick-cursor></i></div>',

			responsive: [
				{
					breakpoint: 9999,
					settings: "unslick"
				},
				{
					breakpoint: 1024,
					 settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
					}
				},
				{
					breakpoint: 600,
					 settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					}
				},
			]
		});
	};

	$(window).resize(articleGallerySlick);
	articleGallerySlick();
});
